<template>
  <el-card class="box-card" >

    <el-alert v-if="hasEditedFields" type="warning" show-icon style="margin-bottom: 15px" :closable="false"> Ci sono modifiche non salvate </el-alert>

    <el-descriptions :key="updateView" v-if="currentUser" :column="3" border class="margin-top table-fixed"
                     direction="vertical" title="Info Utente">
      <template #extra>
        <el-button v-if="hasEditedFields"  type="primary" @click="saveEdits"><i class="el-icon-upload"></i>
          Salva Modifiche
        </el-button>
      </template>
      <el-descriptions-item v-for="user in userDescriptionFields" :key="user.field" :span="user.span">
        <template #label>
          <div class="justify-space-between" style="display: flex; align-items: flex-end">
            <span>
              <i :class="user.icon"></i> {{ user.label }}
            </span>
            <span v-if="user.editable && (this.appUser.roles.includes('ROLE_MANAGER') || this.appUser.roles.includes('ROLE_ADMIN'))" class="edit-button pointer" @click="user.onEdit(user)"><i
                class="el-icon-edit"></i>Modifica</span>
          </div>
        </template>
        {{ user.value }}
      </el-descriptions-item>
    </el-descriptions>

    <p></p>

    <el-row v-if="currentUser">
      <el-col v-if="currentUser.type === 'admin'" :span="6"> <!-- :offset="index > 0 ? 1 : 0 -->
        <el-card>
          <p class="el-descriptions__title"> <span class="el-icon-picture"></span> Logo azienda</p>
          <el-avatar
              v-if="currentUser"
              shape="square"
              :size="200"
              :src="currentUser.imgData"
              fit="contain">
          </el-avatar>
          <div v-if="this.appUser.roles.includes('ROLE_MANAGER') || this.appUser.roles.includes('ROLE_ADMIN')" style="padding: 14px;">
            <!--isGranted('ROLE_MANAGER') Non funziona-->
            <div class="bottom">
              <el-button type="text" class="button" @click="editImg">Aggiungi/Modifica immagine</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <p></p>

    <el-descriptions v-if="currentUser && appUser && currentUser.id === appUser.id" :column="3" border class="margin-top" direction="vertical" title="Avanzate">
      <el-descriptions-item :span="1">
        <template #label>
          <div class="justify-space-between">
            <span>
              <i class="el-icon-key"></i> Azioni
            </span>
          </div>
        </template>
        <div>
          <el-button plain type="primary" @click="$refs.passwordDialog.show()"> Aggiorna Password</el-button>
        </div>
      </el-descriptions-item>
    </el-descriptions>

    <p></p>

    <el-descriptions v-if="currentUser && isGranted('ROLE_MANAGER')" :column="3" border
                     class="margin-top" direction="vertical" title="Amministrazione">
      <el-descriptions-item :span="1">
        <template #label>
          <div class="justify-space-between">
            <span>
              <i class="el-icon-lock"></i> Ruoli
            </span>
          </div>
        </template>
        <div>
          <el-checkbox v-model="hasRole.ROLE_MANAGER" border disabled label="Manager"></el-checkbox>
          <el-checkbox v-model="hasRole.ROLE_ADMIN" border disabled label="Super User"></el-checkbox>
        </div>
      </el-descriptions-item>
      <el-descriptions-item :span="1">
        <template #label>
          <div class="justify-space-between">
            <span>
              <i class="el-icon-key"></i> Azioni
            </span>
          </div>
        </template>
        <div>
          <el-button plain type="primary" @click="dialogResetPassword = true"> Resetta Password</el-button>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div class="justify-space-between">
            <span>
              <i class="el-icon-lock"></i> Attivo
            </span>
            <span style="float: right" class="pointer" @click="updateActive"><i class="el-icon-upload"></i> Salva</span>
          </div>
        </template>
        <el-switch v-model="currentUser.active"></el-switch>
      </el-descriptions-item>
    </el-descriptions>

    <simple-input-dialog ref="simpleInputDialog" @submit="updateCurrentField"></simple-input-dialog>
    <form-dialog ref="addressDialog" :fields="addressFields" title="Modifica Indirizzo"
                 @submit="onUpdateAddress"></form-dialog>
    <form-dialog ref="passwordDialog" :fields="passwordFields" title="Modifica Password"
                 @submit="onUpdatePassword"></form-dialog>
    <simple-select-dialog ref="simpleSelectDialog" @submit="updateCurrentSelect"></simple-select-dialog>
    <simple-img-upload-dialog ref="simpleImgUploadDialog"></simple-img-upload-dialog>
    <el-dialog v-model="dialogResetPassword" title="Attenzione" width="30%" >
      <el-alert type="warning" description="Sei sicuro di voler resettare la password dell'utente?" show-icon :closable="false"></el-alert>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogResetPassword = false">Annulla</el-button>
        <el-button type="warning" @click="submitResetPassword">Conferma</el-button>
      </span>
      </template>
    </el-dialog>
    <el-button style="margin: 15px" v-if="hasEditedFields" type="primary" @click="saveEdits"><i class="el-icon-upload"></i>
      Salva Modifiche
    </el-button>
  </el-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserMixin from "../../mixins/UserMixin";
import SimpleInputDialog from "../../components/SimpleInputDialog";
import SimpleImgUploadDialog from "../../components/SimpleImgUploadDialog";
import FormDialog from "../../components/FormDialog";
import {getAddressFields, getUpdatePasswordFields} from "../../static/formFields";
import AddressMixin from "../../mixins/AddressMixin";
import SimpleSelectDialog from "../../components/SimpleSelectDialog";
import {resetPassword, updatePassword} from "../../api/user.api";

export default {
  name: "User",
  mixins: [UserMixin, AddressMixin],
  components: {SimpleSelectDialog, FormDialog, SimpleInputDialog, SimpleImgUploadDialog},
  data() {
    return {
      userDescriptionFields: [],
      addressFields: [],
      hasEditedFields: false,
      editedFields: {},
      currentField: null,
      hasRole: {
        ROLE_MANAGER: false,
        ROLE_ADMIN: false,
      },
      passwordFields: [],
      dialogResetPassword: false,
      updateView: false,
    }
  },
  computed: {
    ...mapGetters(["currentUser", "appUser", "isGranted"]),
  },
  methods: {
    ...mapActions(["fetchUser", "updateUser", "updateUserRoles", "updateUserActive"]),
    getConfirm() {
      if (this.hasEditedFields)
        return 'Are you sure you want to leave the current page? \n(All unsaved changes will be lost)';
    },
    editField(field) {
      this.currentField = field;
      this.$refs.simpleInputDialog.title = field.label;
      this.$refs.simpleInputDialog.formData = field.value;
      this.$refs.simpleInputDialog.show();
    },
    updateCurrentField(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      this.currentField.value = data;
    },
    editSelect(field) {
      this.currentField = field;
      this.$refs.simpleSelectDialog.title = field.label;
      this.$refs.simpleSelectDialog.formData = field.value;
      this.$refs.simpleSelectDialog.items = this.getTypeSelectFields();
      this.$refs.simpleSelectDialog.show();
    },
    editImg(field) {
      this.currentField = field;
      this.$refs.simpleImgUploadDialog.show();

    },
    updateCurrentSelect(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      this.currentField.value = this.getTypeLabel(data);
    },
    async saveEdits() {
      await this.updateUser({...this.editedFields});
      this.hasEditedFields = false;
    },
    async updateRoles() {
      let roles = [];
      for (let r in this.hasRole) {
        if (this.hasRole[r]) {
          roles.push(r);
        }
      }
      await this.updateUserRoles(roles);
    },
    async updateActive() {
      await this.updateUserActive();
    },
    editAddress(field) {
      this.currentField = field;
      this.addressFields = getAddressFields(this.currentUser.address || {});
      this.$refs.addressDialog.show();
    },
    onUpdateAddress(data) {
      this.hasEditedFields = true;
      if (this.currentUser.address != null) {
        this.editedFields.address = this.currentUser.address;
      } else {
        this.editedFields.address = {};
      }
      for (let field in data.address) {
        this.editedFields.address[field] = data.address[field];
      }
      this.currentField.value = this.stringifyAddress(this.editedFields.address);
    },
    async onUpdatePassword(data) {
      await updatePassword(this.currentUser.id, data);
      this.$message.success('Password aggiornata correttamente');
    },
    async submitResetPassword() {
      this.dialogResetPassword = false;
      await resetPassword(this.currentUser.id);
      this.$message.success('Password resettata correttamente');
    },
    createFields(){
      this.userDescriptionFields = [
        {
          field: "name",
          value: this.currentUser.name,
          label: "Nome",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }, {
          field: "surname",
          value: this.currentUser.surname,
          label: "Cognome",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }, {
          field: "businessName",
          value: this.currentUser.businessName,
          label: "Ragione Sociale",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }, {
          field: "fiscalCode",
          value: this.currentUser.fiscalCode,
          label: "CF/P.IVA",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }, {
          field: "address",
          value: this.stringifyAddress(this.currentUser.address),
          label: "Indirizzo",
          icon: "el-icon-location-outline",
          span: 2,
          editable: true,
          onEdit: this.editAddress,
        }, {
          field: "email",
          value: this.currentUser.email,
          label: "Email / User",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }, {
          field: "phoneNumber1",
          value: this.currentUser.phoneNumber1,
          label: "Telefono 1",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }, {
          field: "phoneNumber2",
          value: this.currentUser.phoneNumber2,
          label: "Telefono 2",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }, {
          field: "type",
          value: this.getTypeLabel(this.currentUser.type),
          label: "Tipo utente",
          icon: "el-icon-s-flag",
          span: 1,
          editable: true,
          onEdit: this.editSelect,
        }, {
          field: "notes",
          value: this.currentUser.notes,
          label: "Note",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }
      ];

      this.passwordFields = getUpdatePasswordFields();
      this.hasRole["ROLE_MANAGER"] = this.currentUser.roles.indexOf("ROLE_MANAGER") >= 0
      this.hasRole["ROLE_ADMIN"] = this.currentUser.roles.indexOf("ROLE_ADMIN") >= 0

      if ((this.appUser.roles.includes('ROLE_MANAGER') || this.appUser.roles.includes('ROLE_ADMIN'))){
        this.userDescriptionFields.push({
          field: "userCode",
          value: this.currentUser.userCode,
          label: "Codice Univoco Utente",
          icon: "el-icon-user",
          span: 1,
          editable: false,
        })
      }
    }
  },
  async created() {

    let userId = this.$route.params.id;
    if (!userId) {
      userId = this.appUser.id;
    }
    await this.fetchUser(userId);

    this.createFields();

    window.onbeforeunload = () => {
      return this.getConfirm();
    };

  },

  // per ritrovare il current user quando refresho la pagina
  async mounted() {
    let userId = this.$route.params.id;
    if (!userId) {
      userId = this.appUser.id;
    }
    await this.fetchUser(userId);
  },
  watch: {
    async $route(to, from) {
      if (to !== from && to.name === from.name) {
        let userId = this.$route.params.id;
        if (!userId) {
          userId = this.appUser.id;
        }
        await this.fetchUser(userId);
        this.createFields()
      }
    }
  }
}

</script>

<style>
.table-fixed .el-descriptions__body table {
  table-layout: fixed;
}


</style>
